import { Triggers } from 'src/app/enums/triggers.enum';

export let valueMap = new Map ([
  [Triggers.CarouselSlideOneClick, 2],
  [Triggers.CarouselSlideTwoClick, 1],
  [Triggers.CarouselSlideThreeClick, 1],
  [Triggers.WorkingWithUs, 3],
  [Triggers.OurQuickWins, 3],
  [Triggers.TemplateHomeLink, 5],
  [Triggers.TemplateShopLink, 5],
  [Triggers.ClientGridLink, 2],
  [Triggers.ServiceTierSelect, 5],
  [Triggers.ProfileImage, 1],
  [Triggers.FooterLinks, 1],
  [Triggers.AdminLoginAttempt, 1],
  [Triggers.ContactFormSubmit, 10]
]);
