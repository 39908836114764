import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GaTrigger } from 'src/app/enums/google.enum';

declare let gtag: Function;

@Component({
  selector: 'bitbatbow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  trigger = GaTrigger.Page;

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.google, { trigger: event.urlAfterRedirects } );
      }
    });
  }

  ngOnInit() {
  }
}
