import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { Page } from 'src/app/enums/page.enum';

export interface Images {
  header: string;
  logo: string;
  main: string;
  preview: string;
  screenshots: string;
}

export interface Portfolio {
  id: number;
  date: string;
  name: string;
  short: string;
  tagline: string;
  tags: Array<string>;
  video: string;
  website: string;
  solution: Array<string>;
  business: Array<string>;
  images: Images
}

@Component({
  selector: 'bitbatbow-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  @Input() public portfolio: Observable<Portfolio[]>;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  constructor(private route: Router) { }

  ngOnInit(): void { }

  public client(client: string) {
    this.route.navigate([Page.Client, client]);
  }

}
