import { Triggers } from 'src/app/enums/triggers.enum';
import { GaAction } from 'src/app/enums/google.enum';

export let actionMap = new Map ([
  [Triggers.CarouselSlideOneClick, GaAction.Click],
  [Triggers.CarouselSlideTwoClick, GaAction.Click],
  [Triggers.CarouselSlideThreeClick, GaAction.Click],
  [Triggers.WorkingWithUs, GaAction.Click],
  [Triggers.OurQuickWins, GaAction.Click],
  [Triggers.TemplateHomeLink, GaAction.Click],
  [Triggers.TemplateShopLink, GaAction.Click],
  [Triggers.ClientGridLink, GaAction.Click],
  [Triggers.ServiceTierSelect, GaAction.Click],
  [Triggers.ProfileImage, GaAction.Click],
  [Triggers.FooterLinks, GaAction.Click],
  [Triggers.AdminLoginAttempt, GaAction.Submit],
  [Triggers.ContactFormSubmit, GaAction.Submit]
]);
