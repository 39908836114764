import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bitbatbow-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() heading: string;
  @Input() subheading?: string;
  @Input() image?: string;
  public background: string;

  constructor() { }

  ngOnInit() {
    this.background = this.image ? this.image : '/assets/img/rebrand/remote-working.png';
  }

}
