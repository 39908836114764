
export enum GaTrigger {
  Page = 'page_path',
  Event = 'event'
}

export enum GaCategory {
  Browsing = 'Browsing',
  Potential = 'Potential',
  Purchase = 'Purchase',
  Revenue = 'Revenue',
  Contact = 'Contact',
  Login = 'Login',
  Other = 'Other'
}

export enum GaAction {
  Click = 'Click',
  Scroll = 'Scroll',
  Submit = 'Submit',
}


