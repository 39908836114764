import { Component, Inject, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { Triggers } from 'src/app/enums/triggers.enum';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

const socialMediaMap = new Map ([
  ['linkedin', 'https://www.linkedin.com/company/bitbatbow'],
  ['github', 'https://github.com/bitbatbow'],
  ['instagram', 'https://instagram.com/bitbatbow'],
  ['youtube', 'https://www.youtube.com/channel/UCZoCeKLEjlsdrxgQG45FQ8Q'],
]);

@Component({
  selector: 'bitbatbow-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public date: number;
  public url: string;

  constructor(
    private google: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
      }
    })
  }

  ngOnInit() {
    this.date = Date.now();
  }

  socials(link: string) {
    const external = this.getLink(link);
    this.google.event(Triggers.FooterLinks, `footer-to-${link}-from-${this.router.url}`);
    window.open(external, '_blank');
  }

  public home() {
    if(this.url === '/') {
      this.document.querySelector('#home').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  public about() {
    if(this.url === '/') {
      this.document.querySelector('#about').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  public portfolio() {
    if(this.url === '/') {
      this.document.querySelector('#portfolio').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    } 
  }

  public contact() {
    if(this.url === '/') {
      this.document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  private getLink(link) {
    return socialMediaMap.get(link);
  }

}
