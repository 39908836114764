import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare const window: any;
declare var $: any;

@Component({
  selector: 'bitbatbow-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public open: boolean;
  public url: string;

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
      }
    })
  }

  ngOnInit() {
    this.open = false;
  }

  public toggleNavigation() {
    this.open = !this.open;
  }

  public home() {
    if(this.url === '/') {
      this.document.querySelector('#home').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  public about() {
    if(this.url === '/') {
      this.document.querySelector('#about').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  public portfolio() {
    if(this.url === '/') {
      this.document.querySelector('#portfolio').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    } 
  }

  public contact() {
    if(this.url === '/') {
      this.document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }
}
