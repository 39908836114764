import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page } from './enums/page.enum';

const routes: Routes = [
  { 
    path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule)
  },
  { 
    path: Page.Error, loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: Page.Quote, loadChildren: () => import('./components/pages/quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: Page.Client, loadChildren: () => import('./components/pages/client/client.module').then(m => m.ClientModule)
  },
  {
    path: Page.Cookies, loadChildren: () => import('./components/pages/cookies/cookies.module').then(m => m.CookiesModule)
  },
  {
    path: Page.Privacy, loadChildren: () => import('./components/pages/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  { 
    path: '**', redirectTo: Page.Error, pathMatch: 'full' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
