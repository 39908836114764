import { Injectable } from '@angular/core';
import { Triggers } from 'src/app/enums/triggers.enum';
import { GaTrigger } from 'src/app/enums/google.enum';
import { categoryMap } from './category-map.data';
import { actionMap } from './action-map.data';
import { valueMap } from './value-map.data';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public event(trigger: Triggers, label: string) {
    gtag(GaTrigger.Event, trigger, {
      event_category: this.getCategory(trigger),
      event_label: label,
      event_action: this.getAction(trigger),
      value: this.getValue(trigger)
    });
  }

  private getCategory(trigger: Triggers) {
    return categoryMap.get(trigger);
  }

  private getAction(trigger: Triggers) {
    return actionMap.get(trigger);
  }

  private getValue(trigger: Triggers) {
    return valueMap.get(trigger);
  }
}
