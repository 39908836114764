export enum Triggers {
  CarouselSlideOneClick = 'Carousel_SlideOne_Click',
  CarouselSlideTwoClick = 'Carousel_SlideTwo_Click',
  CarouselSlideThreeClick = 'Carousel_SlideThree_Click',
  WorkingWithUs = 'Working_With_Us',
  OurQuickWins = 'Our_Quick_Wins',
  TemplateHomeLink = 'Template_Home_Link',
  TemplateShopLink = 'Template_Shop_Link',
  ClientGridLink = 'Client_Grid_Link',
  ServiceTierSelect = 'Service_Tier_Select',
  ProfileImage = 'Profile_Image',
  FooterLinks = 'Footer_Links',
  AdminLoginAttempt = 'Admin_Login_Attempt',
  ContactFormSubmit = 'Contact_Form_Submit',
  CartFormSubmit = 'Cart_Form_Submit',
  Subscribed = 'Subscription',
  Purchasing = 'Purchasing',
  OpenDemoVideo = 'Open_Demo_Video',
  PaymentSuccess = 'Payment_Success',
  PaymentCancel = 'Payment_Cancel',
  PaymentError = 'Payment_Error'
}

export enum Product {
  Homes = 'homes',
  Shop = 'shop',
  Adhoc = 'adhoc',
  Support = 'support'
}