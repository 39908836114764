import { Triggers } from 'src/app/enums/triggers.enum';
import { GaCategory } from 'src/app/enums/google.enum';

export let categoryMap = new Map ([
  [Triggers.CarouselSlideOneClick, GaCategory.Browsing],
  [Triggers.CarouselSlideTwoClick, GaCategory.Browsing],
  [Triggers.CarouselSlideThreeClick, GaCategory.Browsing],
  [Triggers.WorkingWithUs, GaCategory.Other],
  [Triggers.OurQuickWins, GaCategory.Browsing],
  [Triggers.TemplateHomeLink, GaCategory.Potential],
  [Triggers.TemplateShopLink, GaCategory.Potential],
  [Triggers.ClientGridLink, GaCategory.Browsing],
  [Triggers.ServiceTierSelect, GaCategory.Potential],
  [Triggers.ProfileImage, GaCategory.Other],
  [Triggers.FooterLinks, GaCategory.Browsing],
  [Triggers.AdminLoginAttempt, GaCategory.Login],
  [Triggers.ContactFormSubmit, GaCategory.Contact]
]);
