import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'bitbatbow-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
  })
  export class BreadcrumbsComponent implements OnInit {
    @Input() public page: string;
  
    constructor() { }
  
    ngOnInit() {
    }
  }